








































































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import Client from "@/models/Client";
import Address, {AddressParentTypes} from "@/models/Address";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ClientService from "@/services/ClientService";
import AddressService from "@/services/AddressService";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import Rules from "@/services/tool/Rules";
import Handler from "@/handlers/Handler";
import {SingleItem} from "@/handlers/interfaces/ContentUI";

@Component
export default class CreateAddressDialog extends Vue {

	@Prop({ default: undefined })
  readonly clientId!: number | undefined

  @Prop({ type: String, default: AddressParentTypes.CLIENT })
  readonly addressType!: AddressParentTypes

	@Ref()
  readonly form!: HTMLFormElement

  loading: boolean = false
	lang: any = getModule(LangModule).lang
	address: Address = new Address()

  address2: SingleItem<Address> = { item: new Address() }

  get rules2() { return Rules }


	rules = {
		required: (input: string) => (input ? true : this.lang.required),
		email: (input: string) => input || StringTool.validateEmail(input) ? true : this.lang.emailNoValid,
		postalCode: (input: string) => input || StringTool.validatePostalCode(input) ? true : this.lang.invalidPostalCode,
	}

	basicRules = [
		(input: string | undefined) => (input != undefined ? true : this.lang.pleaseFillField),
		(input: string  | undefined) => input != undefined && input.length >= 4 ? true : this.lang.fieldMin4Characters,
		(input: string  | undefined) => input != undefined && input.length <= 256 ? true : this.lang.fieldMax256Characters,
	]

	closeDialog() {
		this.$emit("close")
		// @ts-ignore
		this.$refs.form.reset()
	}

	refresh() {
		this.$emit("refresh")
	}

	createAddress() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(
				 new Dialog(this.lang.warning, this.lang.createAddressConfirmation, async () => {

           switch (this.addressType) {
             case AddressParentTypes.CLIENT: {
               await AddressService.postAddress(this, this.address, this.clientId!); break
             }

             case AddressParentTypes.POTENTIAL_CLIENT: {
               await AddressService.postAddressToPotentialClient(this, this.address, this.clientId!); break
             }

             case AddressParentTypes.HEADQUARTER: {
               await Handler.getItem(this, this.address2, () =>
                AddressService.postHeadquarterAddress(this.clientId!!, this.address)
               ); break
             }

             default: { break }

           }

           this.closeDialog()
           this.$emit("afterCreated", this.address2)
					 this.$emit("refresh")
				 })


			)
		}
	}
}
