import ConstantTool from "@/services/tool/ConstantTool";
import Address, {AddressType} from "@/models/Address";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import {Vue} from "vue-property-decorator";
import JsonTool from "@/services/tool/JsonTool";
import axios from "axios";
import Response from "@/models/responses/Response";

export default class AddressService {

    static async getAddress(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/address/"+ id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })

            // @ts-ignore
            component.address = JsonTool.jsonConvert.deserializeObject(response.data, Address)

        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo obtener la direccion")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async postAddress(component: Vue, address: Address, clientId: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.post(
                ConstantTool.BASE_URL + "/api/@me/clients/"+ clientId +"/addresses", address, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
            // @ts-ignore
            component.form.reset()
            // @ts-ignore
            component.loading = false
            // @ts-ignore
            component.dialog = false
        } catch (err) {
            console.log(err)
            getModule(SnackbarModule).makeToast("No se pudo crear la direccion")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async postAddressToPotentialClient(component: Vue, address: Address, potentialClientId: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.post(ConstantTool.BASE_URL + "/api/@me/potential-clients/"+ potentialClientId +"/addresses", address, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
            // @ts-ignore
            component.form.reset()
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudieron encontrar tus direcciones")
        } finally {
            // @ts-ignore
            component.loading = false
        }

    }

    static async postHeadquarterAddress(headquarterId: number, request: Address): Promise<Response<Address>> {
        try {
            const response = await axios.post(ConstantTool.BASE_URL + `/api/headquarters/${headquarterId}/addresses`, request, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const address = JsonTool.jsonConvert.deserializeObject(response.data, Address)
            return Promise.resolve({ result: address })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async getAddressesByClient(clientId: number): Promise<Response<Address[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/clients/"+ clientId +"/addresses", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const addresses = JsonTool.jsonConvert.deserializeArray(response.data, Address)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: addresses, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async getAddressByClient(component: Vue, addresses: Address[], clientId: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(
                ConstantTool.BASE_URL + "/api/@me/clients/"+ clientId +"/addresses", {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            addresses.splice(0, addresses.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Address).forEach(v => addresses.push(v))
            // @ts-ignore
            component.loading = false
        } catch (err) {
            console.log(err)
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se pudieron encontrar tus direcciones")
        }
    }

    static async getAddressesByPotentialClient(component: Vue, addresses: Address[], potentialClientId: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/potential-clients/"+ potentialClientId +"/addresses", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            addresses.splice(0, addresses.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Address).forEach(v => addresses.push(v))
        } catch (e) {
            getModule(SnackbarModule).makeToast("Ha ocurrido un error buscando sus direcciones")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getHeadquarterAddresses(headquarterId: number): Promise<Response<Address[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + `/api/headquarters/${headquarterId}/addresses`, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const addresses = JsonTool.jsonConvert.deserializeArray(response.data, Address)
            return Promise.resolve({ result: addresses })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static patchAddress(component: Vue, address: Address) {
        component.axios.patch(ConstantTool.BASE_URL + "/api/@me/addresses/" + address.id, address,{
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                // @ts-ignore
                component.refresh()
                // @ts-ignore
                component.addressDialog = false
            })
            .catch(error => getModule(SnackbarModule).makeToast("No se pudo modificar la dirección"))
    }

    static deleteAddress(component: Vue, id: number) {
        component.axios.delete(ConstantTool.BASE_URL + "/api/@me/addresses/" + id, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                // @ts-ignore
                component.refresh()
                // @ts-ignore
                component.addressDialog = false
                getModule(SnackbarModule).makeToast("Dirección eliminada exitosamente")
            })
            .catch(error => getModule(SnackbarModule).makeToast("No se pudo eliminar la dirección"))
    }

}
