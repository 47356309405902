









































































































import HeadquarterService from "@/services/HeadquarterService"
import {SingleItem} from "@/handlers/interfaces/ContentUI"
import {Vue, Component} from 'vue-property-decorator'
import {getModule} from "vuex-module-decorators"
import Headquarter from "@/models/Headquarter"
import LangModule from "@/store/LangModule"
import Rules from "@/services/tool/Rules"
import Handler from "@/handlers/Handler"
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import HeadquarterFileTab from "@/components/headquarter/HeadquarterFileTab.vue";
import HeadquarterAddressesTab from "@/components/headquarter/HeadquarterAddressesTab.vue";

@Component({
  components: {HeadquarterAddressesTab, HeadquarterFileTab}
})
export default class HeadquarterView extends Vue {

  get lang() {
    return getModule(LangModule).lang
  }

  get rules() {
    return Rules
  }

  loading: boolean = false
  headquarter: SingleItem<Headquarter> = {item: new Headquarter()}
  tab: number = 0


  created() {
    this.refresh()
  }

  async refresh() {
    await Handler.getItem(this, this.headquarter, () => HeadquarterService.getById(Number(this.$route.params.id)))
  }

}
